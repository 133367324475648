import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const HowDoesItWork = () => (
  <Layout pageInfo={{ pageName: "hoe-werkt-het", pageTitle: "Hoe werkt het?" }}>
    <Seo title="Hoe werkt het?"/>

    <section className="general-page" id="how-does-it-work">
      <Container>
        <Row>
          <Col>
            <div className="block">
              <h3 className="title">Je stuurt een tekening op.</h3>
              <p>
                Je kunt de tekening inscannen of er een goede, scherpe foto van maken en deze per e-mail opsturen.
                Natuurlijk kan ook de originele tekening per post worden opgestuurd.
              </p>

              <h3 className="title">Beschrijf kort de situatie.</h3>
              <p>
                Om een tekening goed te kunnen analyseren is het fijn een aantal dingen te weten. Allereerst de
                geboortedatum en het geslacht van de tekenaar, wanneer de tekening is gemaakt en of de tekenaar keuze
                had uit verschillende materialen (zowel om mee te tekenen als om op te tekenen). Daarnaast de reden
                waarom ervoor wordt gekozen een tekening te laten analyseren, een korte beschrijving van de persoonlijke
                situatie van de tekenaar (denk aan de gezinssituatie en school) en ten slotte eventuele bijzonderheden.
              </p>

              <h3 className="title">De tekening wordt geanalyseerd.</h3>
              <p>
                Ik ga de tekening analyseren. Ik kijk daarbij naar een heleboel dingen, zoals bijvoorbeeld figuren en
                kleuren, maar ook plaatsing op het blad en symboliek. Uiteindelijk worden alle bevindingen samengevoegd
                in een conclusie.
              </p>

              <h3 className="title">Het delen van de analyse.</h3>
              <p>
                Na het analyseren bestaan er verschillende mogelijkheden.
                Allereerst kan de analyse per e-mail worden verstuurd of per post worden toegezonden zodat deze rustig
                kan worden doorgelezen.
                Er kan ook voor worden gekozen om een afspraak met mij te maken (in mijn praktijk of via skype) om de
                analyse samen te bespreken. Er kunnen dan vragen over de analyse worden gesteld en ik kan
                - indien nodig - tips en adviezen geven naar aanleiding van de analyse.
                Natuurlijk kan ook worden gekozen voor een combinatie van beiden, alles kan in een persoonlijk gesprek
                (of via skype) worden besproken waarna de analyse (inclusief een korte samenvatting van het gesprek) per
                e-mail wordt toegestuurd zodat deze nog kan worden nagelezen.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default HowDoesItWork
